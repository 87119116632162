:root {
    --aviapro-red-color: #a50005;
    --dark-grey: #858585;
    --light-grey: #e9e9e9;
}

.custom-hover-color:hover > * {
    color: var(--aviapro-red-color) !important;
}

.custom-range {
    position: relative;
    background: var(--light-grey);
    &::-webkit-slider-thumb {
        position: relative;
        z-index: 10;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        background: var(--dark-grey);
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        -webkit-appearance: none;
    }
    &::-webkit-slider-runnable-track {
        position: relative;
        z-index: 10;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        -webkit-appearance: none;
    }
    &::-moz-range-track {
        position: relative;
        z-index: 5;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        -moz-appearance: none;
    }
    &::-moz-range-thumb {
        position: relative;
        z-index: 50;
        // opacity: 1;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        background: var(--dark-grey);
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        -moz-appearance: none;
    }
}

.custom-range-label {
    background: var(--dark-grey);
}

.custom-slider-bullet-index {
    background-color: var(--dark-grey);
    transform: translateY(50%) rotate(45deg);
}

.custom-checkbox {
    border-width: 0.1rem;
    border-color: var(--aviapro-red-color);
    border-radius: 0.125rem;
}

.custom-checkbox-text-validation:hover {
    color: black !important;
}

.custom-checkbox-text:hover {
    color: var(--aviapro-red-color) !important;
}

.custom-submission-input {
    border-color: var(--aviapro-red-color);
}

.custom-submission-input::placeholder {
    opacity: 0;
}

.custom-submission-input {
    border-color: var(--aviapro-red-color);
}

.custom-submission-input:focus + .custom-submission-input-text,
.custom-submission-input:valid + .custom-submission-input-text {
    font-size: x-small;
    transform: translateY(-135%) !important;
}

.custom-submission-input-text {
    color: var(--dark-grey);
    position: absolute;
    display: block;
    transform: translateY(-100%);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
}

.custom-submission-email-input-text {
    padding-left: 0.75rem;
    margin-left: 2.5rem;
    transform: translateY(2%) !important;
}

.custom-submission-input:focus + .custom-submission-email-input-text,
.custom-submission-input:valid + .custom-submission-email-input-text {
    transform: translateY(-33%) !important;
}

.custom-OEM-selection-button:hover > .custom-OEM-selection-radio {
    box-shadow: 0 0 0 1px var(--aviapro-red-color) !important;
}

.custom-dropdown {
    appearance: none;
    border-width: 1px;
    border-color: var(--aviapro-red-color);
    border-radius: 0.25rem;
    color: var(--aviapro-red-color);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-size: 0.5em;
    background-position: calc(100% - 1rem);
    background-repeat: no-repeat;
}

.custom-back-button:hover {
    background-color: #fbf2f2;
}

.custom-back-button {
    border-color: var(--aviapro-red-color);
}

.custom-interaction-button:hover {
    background-color: #b91414 !important;
}

.custom-interaction-button {
    border-color: var(--aviapro-red-color);
    background-color: var(--aviapro-red-color) !important;
}

.custom-next-button:hover {
    background-color: #b91414 !important;
}

.custom-next-button {
    border-color: var(--aviapro-red-color);
    background-color: var(--aviapro-red-color) !important;
}

.tooltip-background {
    background: #a50000;
}

.fixed-mach-box {
    max-width: 10.4rem !important;
}

.date-placeholder {
    text-transform: lowercase !important;
}

.airport-placeholder::-webkit-input-placeholder {
    text-transform: capitalize;
}

input {
    text-transform: capitalize;
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }
}

@keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }
}

.chevron-up {
    transition: all 0.2s;
    transform: rotate(-180deg);
}

.chevron-down {
    transition: all 0.2s;
    transform: rotate(-360deg);
}

.border-color {
    border: 1px solid;
    border-color: #b91314;
}

.border-left {
    border-left: 1px solid;
    border-color: #b91314;
}

.border-bottom {
    border-bottom: 1px solid;
    border-color: #b91314;
}

.border-right {
    border-right: 1px solid;
    border-color: #b91314;
}

.border-top {
    border-top: 1px solid;
    border-color: #b91314;
}
