@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@400;500;600;700;800&display=swap");

* {
    font-family: "Barlow";
}

html,
body {
    height: 100%;
    width: 100%;
}

#root {
    height: auto;
    width: 100%;
    position: relative;
    min-height: 100%;
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
}

.fade-exit.fade-exit-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}
